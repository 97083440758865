import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

  const navItems = [
        {
          name:"ABOUT",
          url:"/page/about"
        },
        {
          name:"WORK",
          url:"/page/work"
        }
      ]
      
function header(){
  return (
      <div className="header" >
        <div className="innerlayout" >
          <div className="companyLogoLayout" >
            <Link to="/">
              <h4>JUNed</h4>
            </Link>
          </div>
            <ul>
              {navItems.map((data,indexValue)=>(
                <li key={indexValue} className="webLayout">
                  <Link to={data.url}>
                      {data.name}
                  </Link>
                </li>
              ))}
              <li className="webLayout"> <a href="/juned_rajbhara_resume.pdf" target="_blank" rel="noreferrer" >RESUME</a></li>
            </ul>
        </div>
      </div>
    );
}

export default header;