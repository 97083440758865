import React, { useEffect } from "react";
import "./home.scss";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { currentCompany } from "../../constant";
const skillsList = [
  {
    title: "Python",
    url: "https://www.python.org/static/opengraph-icon-200x200.png",
  },
  {
    title: "aws",
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/300px-Amazon_Web_Services_Logo.svg.png",
  },
  {
    title: "python",
    url:
      "https://cdn.iconscout.com/icon/free/png-256/postgresql-11-1175122.png",
  },
  {
    title: "jinja",
    url:
      "https://jinja.palletsprojects.com/en/3.0.x/_images/jinja-logo.png",
  },
  {
    title: "Github",
    url: "https://github.githubassets.com/images/modules/logos_page/Octocat.png",
  },
  {
    title: "CSS",
    url:"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1200px-CSS3_logo_and_wordmark.svg.png"
  },  
  {
    title: "JavaScript",
    url:"https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Javascript_badge.svg/710px-Javascript_badge.svg.png"
  },  
  {
    title: "Django",
    url:"https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Django_logo.svg/2560px-Django_logo.svg.png"
  },  
  {
    title: "React",
    url:"/logo.svg"
  }
];
const logoanimator = {
  hidden: { scale: 0, top: 100 },
  animate: {
    top: 30,
    scale: 1,
    rotate: [0, 0, 270, 270, 0],
  },
  whileHover: { scale: 1.5 },
};

const container = {
  hidden: { scale: 0, top: 100 },
  animate: {
    scale: 1,
    top: 30,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.6,
    },
  },
};

const middleCircle = {
  hidden: {
    opacity: 0,
    marginLeft: -500,
  },
  animate: {
    marginLeft: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
};

const links = {
  hidden: {  },
  whileHover: { },
  animate: {
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.6,
    },
  },
};

const linkChild = {
  hidden: {
    opacity: 0,
    marginLeft: -100,
  },
  animate: {
    marginLeft: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
  whileHover: { scale: 1.2 },
};

function Home() {
  return (
    <div className="homebody">
      <h2 className="biglbl"> About </h2>
      <h2 className="bigsublbl"> About </h2>
      <motion.div className="aboutGrid">
        <motion.div>
          <motion.ul variants={links} initial="hidden" animate="animate"  className="links">
            <motion.li variants={linkChild} whileHover="whileHover"> <a href="/juned_rajbhara_resume.pdf" target="_blank" rel="noreferrer" >Resume</a></motion.li>
            <motion.li variants={linkChild} whileHover="whileHover"> <a href="mailto:junedraj.1997@gmail.com" target="_blank" rel="noreferrer" >Email</a></motion.li>
            <motion.li variants={linkChild} whileHover="whileHover"> <a href="https://wa.me/+918866219787?text=I'm%20interested%20in%20your%20portfolio" target="_blank" rel="noreferrer" >Whatsapp</a></motion.li>
            <motion.li variants={linkChild} whileHover="whileHover"> <a href="https://github.com/skwars7" target="_blank" rel="noreferrer" >Github</a></motion.li>
            <motion.li variants={linkChild} whileHover="whileHover"> <a href="https://www.linkedin.com/in/juned-rajbhara/" target="_blank" rel="noreferrer" >Linkedin</a></motion.li>
          </motion.ul>
          <motion.div variants={links} initial="hidden" animate="animate" className="aboutdesc">
            <motion.span variants={linkChild}>I am JUNed Rajbhara, SDE-1 @ {currentCompany} · Feb 2020 - present · BLR, IND.</motion.span>
            <motion.p variants={linkChild} className="subdesc width20">
            I enjoy building custom frameworks it gives good graps over building complex product process. I think it’s important to get regular screen downtime, so When I'm not coding, you'll find me cooking, hanging out with friends and family or sleeping it out.
            </motion.p>
            <motion.span variants={linkChild} className="subdesc"><br />What I believe</motion.span>
            <motion.span variants={linkChild} className="subdesc">-</motion.span>
            <motion.span variants={linkChild} className="subdesc">01 Requirement</motion.span>
            <motion.span variants={linkChild} className="subdesc">02 Implimentation</motion.span>
            <motion.span variants={linkChild} className="subdesc">03 Visualisation</motion.span>
            <motion.span variants={linkChild} className="subdesc">04 repeat 02 & 03</motion.span>
            <motion.span variants={linkChild} className="thankslbl">Thanks For <br /> stopping by!</motion.span>
          </motion.div>
        </motion.div>
        <motion.div>
          <motion.img
            initial="hidden"
            animate="animate"
            variants={middleCircle}
            style={{ height: "100%", width: "100%", objectFit: "scale-down" }}
            alt="Backend Developer juned rajbhara serverless Architecture python"
            src="/myAvatar.png"
          />
        </motion.div>
      </motion.div>
      <h2 className="skillslbl"> SKILLS </h2>
      <motion.div
        className="skillsgrid"
        variants={container}
        initial="hidden"
        animate="animate"
      >
        {skillsList.map((data, idx) => (
          <FadeInWhenVisible>
            <motion.img
              className="skillogo"
              variants={logoanimator}
              whileHover="whileHover"
              src={data.url}
              alt={data.title}
            />
          </FadeInWhenVisible>
        ))}
      </motion.div>
    </div>
  );
}

const FadeInWhenVisible:React.FC<{}> = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.3, delayChildren: 1, staggerChildren: 0.5 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

export default Home;
