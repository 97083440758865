import React from "react";
import "./welcome.scss";
import { motion } from  'framer-motion';
import { Link } from "react-router-dom";
import { currentCompany } from "../../constant";

const waitTime = 1.5;

const SecondCircle = {
  hidden:{
    opacity:0,
  },
  visible:{
    scale:[0,100],
    opacity:[1,0],
    transition:{delay:0.7,duration:1.8},
    transitionEnd: { display: "none" },
  },
  exit:{
    transitionEnd: { display: "block" },
    x:'-100vw',
    transition:{ ease: 'easeInOut'}
  }
}

const FirstCircle = {
  hidden:{
    opacity:0,
  },
  visible:{
    scale:[0,100],
    opacity:[1,0],
    transition:{duration:2},
    transitionEnd: {display: "none"},
  },
  exit:{
    transitionEnd: { display: "block" },
    x:'-100vw',
    transition:{ ease: 'easeInOut'}
  }
}

const dataContainer = {
  hidden:{
    opacity:0,
  },
  visible:{
    opacity:1,
    transition:{delay:waitTime},
  }
}

const middleCircle = {
  hidden:{
    opacity:0,
    scale:0,
  },
  visible:{
    scale:1,
    opacity:1,
    transition:{delay:waitTime,duration:1},
  },
  exit:{
    x:'-100vw',
    transition:{ ease: 'easeInOut'}
  }
}

const transition = { duration: 1.2 };

const toptextAnime = {
  hidden: { y: 0},
  visible: {
    y: 0,
    transition: {
      when: "beforechildren",
      delayChildren: 2,
      staggerChildren: 2
    }
  },
}

const animatetext = {
  hidden: { y: 10,opacity:0 },
  visible: {
    y: 0,opacity:1,
    transition:{ delay:waitTime, ...transition, }
  }
}

const animatetext2 = {
  hidden: { y: 10,opacity:0 },
  visible: {
    y: [10,0],opacity:1,
    transition:{ delay:waitTime+0.5, duration:1 }
  }
}

const leftanime = {
  hidden: { scaleX: 0 },
  visible: {
    scaleX: 1,
    transition:{ delay:waitTime, ...transition,  }
  }
}

const bottomtextAnime = {
  hidden: { y: -10,opacity:0 },
  visible: {
    y: 0,opacity:1,
    transition:{ delay:waitTime, ...transition,  }
  },
}

const bottomtextAnime2 = {
  hidden: { y: -10,opacity:0 },
  visible: {
    y: 0,opacity:1,
    transition:{ delay:waitTime+0.5, ...transition,  }
  },
}

function Welcome() {
    return (
      <div className="welcomebody">
      <motion.div className="box">
        <motion.svg 
        style={{zIndex:1}}
        variants={FirstCircle}
        initial="hidden"
        animate="visible"
        exit="exit"
        >
          <circle fill="#776cc2" cx="50" cy="50" r="25"/>
        </motion.svg>
        <motion.svg 
        style={{zIndex:1}}
        variants={SecondCircle}
        initial="hidden"
        animate="visible"
        exit="exit"
        >
          <circle fill="white" cx="50" cy="50" r="25"/>
        </motion.svg>
      </motion.div>
      <motion.div 
        className="dataContainer"
        variants={dataContainer}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="leftCircle">
          <Link to="/page/about">ABOUT |</Link>
          <Link to="/page/work"> WORK</Link>
          <a href="/juned_rajbhara_resume.pdf" target="_blank" rel="noreferrer" > | RESUME</a>
          <motion.div variants={leftanime} style={{borderBottom:"1px solid #776cc2",width:"50%"}}></motion.div>
        </motion.div>
        <motion.div className="middleCircle" >
        <motion.img variants={middleCircle} style={{height:'90%',width:"90%", objectFit:"scale-down"}} src="/myAvatar.png" />
        </motion.div>
        <motion.div className="rightDetails">
          <span className="center_verticle">
            <motion.span 
              variants={toptextAnime}
              initial="hidden"
              animate="visible"
              className="heyline"
            >
              <motion.span variants={animatetext}>Hey, I'm&nbsp;</motion.span>
              <motion.span variants={animatetext2}>JUNed! 👋</motion.span>
            </motion.span>
            <motion.div 
              variants={toptextAnime}
              initial="hidden"
              animate="visible"
              className="heyline"
            >
              <motion.span variants={animatetext2}>PYTHON | AWS | SERVERLESS | ETL | REPORTING ENGINE</motion.span>
            </motion.div>
            <motion.span
              variants={toptextAnime}
              className="short_desc"
            >
            <motion.span variants={bottomtextAnime} className="designation">Developer-1 @ {currentCompany} &nbsp;</motion.span> 
            <motion.span variants={bottomtextAnime2} className="nospan">
              Here at Delta Airlines, I've been a part of the Customer Loyalty Data team following Event-Driven serverless Architecture for ETL, Data Pipelines and API's.
            </motion.span>
            {/* <motion.span variants={bottomtextAnime2} className="nospan">
              Over the last two years at Fintso, I've been a part of the core technology team building the serverless reports engine and graphical dashboard (subscriber aggregate, investor, drilldown, etc) using Python on AWS. I have developed a deep understanding of the microservices architecture of the platform.
            </motion.span> */}
          </motion.span>
          </span>
        </motion.div>
      </motion.div>
    </div>
    );
}

export default Welcome;