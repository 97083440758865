import React from "react";
import "./App.scss";
import { Body as Headerwithroutes } from "./common/components/body";
import { Welcome as Headerlessroutes } from "./pages/welcome";
import { Route, Routes } from "react-router-dom";
import {AnimatePresence} from 'framer-motion';

function App() {
  return (
    <div className="App">
      <AnimatePresence exitBeforeEnter>
        <Routes>
              <Route path="/page/*" element={<Headerwithroutes/>} />
              <Route path="/" element={<Headerlessroutes/>} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;