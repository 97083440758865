import React from "react";
import "./Footer.scss";

function Footer() {
    return (
      <div className="footer">
        <div className="bottomNav">
            © 2020 JUNed. All Rights Reserved.
        </div>
      </div>
    );
}

export default Footer;
