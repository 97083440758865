
import { Route, Routes } from "react-router-dom";
import "./Body.scss";
import { Home } from "../../../pages/home";
import { Work } from "../../../pages/work";
import { Footer } from "../footer";
import { Header } from "../header";

function Body() {
  return (
    <>
      <Header />
      <div className="bodyLayout">
        <Routes>
          <Route path="/about" element={<Home />} />
          <Route path="/work" element={<Work />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default Body;
